import React from 'react';
import SectionStyled from '../../kit/section';
import {Typography, Button, Box, useTheme, useMediaQuery} from "@mui/material";

export default function Section2() {
    const theme = useTheme()
    const isSmallMonitor = useMediaQuery(theme.breakpoints.down(1200));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    return (
        <SectionStyled tag={'section'} bg={""} mwidth={'1160px'}
                       sxsect={{
                           height: 0,
                           background: 'linear-gradient(#00000, transparent)',
                       }}
                       sxcont={{
                           display: 'flex',
                           justifyContent: 'flex-end',
                           alignItems: 'center',
                           padding: '0 10px',
                           zIndex: 10
                       }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                backdropFilter: "blur(50px)",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                border: '0.5px solid #41364F',
                borderRadius: '19px',
                maxWidth: '550px',
                minHeight: '326px',
                gap: '10px',
                padding: '33px 20px',
                width: '100%',
                marginTop: isSmallMonitor ? "110px" : "50px",

            }}>
                <Box
                    sx={{
                        borderRadius: '19px',
                        background: 'rgba(38, 40, 53, 0.4)',
                        border: "0.5px solid #41364F",
                        backdropFilter: "blur(36px)",
                        padding: isMobile ? "16px 27px" : "26px 37px",
                        maxWidth: '453px',
                        width: '100%'
                    }}
                >
                    <Typography variant='h5' fontWeight='400' sx={{fontSize: isMobile ? "18px" : "24px"}}>Вставь ссылку в код</Typography></Box>
                <Box
                    sx={{
                        borderRadius: '19px',
                        background: 'rgba(38, 40, 53, 0.4)',
                        border: "0.5px solid #41364F",
                        backdropFilter: "blur(36px)",
                        padding: isMobile ? "16px 27px" : "26px 37px",
                        maxWidth: '453px',
                        width: '100%'
                    }}
                >
                    <Typography variant='h5' fontWeight='400'
                                sx={{fontSize: isMobile ? "18px" : "24px"}}>
                        Изучи результаты</Typography></Box>
                <Box
                    sx={{
                        borderRadius: '19px',
                        border: "0.5px solid #7E2AEA",
                        background: 'rgba(38, 40, 53, 0.91)',
                        boxShadow: "-3px 7px 28px 0px rgba(20, 11, 31, 0.23)",
                        padding: isMobile ? "16px 27px" : "26px 37px",
                        maxWidth: '453px',
                        width: '100%'
                    }}
                >
                    <Typography variant='h5'
                             sx={{ fontWeight: 400, fontSize: isMobile ? "18px" : "24px" }}
                >Развивай свой бизнес</Typography></Box>
            </Box>

        </SectionStyled>
    )
}
