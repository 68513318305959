import SectionStyled from "../../kit/section";
import {Box, Typography, useTheme, useMediaQuery} from "@mui/material";
import React from "react";
import AbTestingIcon from "../../icons/mini/ab-testing.svg";
import GlobalIcon from "../../icons/mini/globe-africa.svg";
import ClockIcon from "../../icons/mini/Icon-Clock.svg";
import ContactsIcon from "../../icons/mini/Icon_contacts.svg";
import GlobalTimeIcon from "../../icons/mini/Icon_global_time.svg";
import HistoryIcon from "../../icons/mini/icon_history.svg";
import MonitoringIcon from "../../icons/mini/Icon_monitoring.svg";
import OthersIcon from "../../icons/mini/Icon_others.svg";
import LinkIcon from "../../icons/mini/link-icon.svg";
import MobileIcon from "../../icons/mini/mobile.svg";
import IconTextCard from "../../kit/IconTextCard";


export default function Section6() {
    const theme = useTheme()
    const isSmallMonitor = useMediaQuery(theme.breakpoints.down(1200));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    const cardArray = [
        {icon: ContactsIcon, headerText: "Интересы посетителей"},
        {icon: ClockIcon, headerText: "Время на сайте"},
        {icon: MonitoringIcon, headerText: "Глубина просмотра"},
        {icon: AbTestingIcon, headerText: "Язык"},
        {icon: HistoryIcon, headerText: "Пол и возраст посетителей"},
        {icon: GlobalTimeIcon, headerText: "Часовой пояс"},
        {icon: LinkIcon, headerText: "Переход по внешней ссылке"},
        {icon: MobileIcon, headerText: "Устройство"},
        {icon: GlobalIcon, headerText: "Географические данные"},
        {icon: OthersIcon, headerText: "Другие данные"},

    ]
    return (
        <SectionStyled tag={'section'} bg={"#333647"} mwidth={'1160px'}
                       sxsect={{
                           minHeight: '934px',
                           marginTop: "-273px",
                           paddingBottom: '44px'
                       }}
                       sxcont={{
                           display: 'flex',
                           flexDirection: 'column',
                           justifyContent: 'space-between',
                           alignItems: 'baseline',
                           padding: '0',
                           gap: '65px',
                           marginTop: isSmallMonitor ? (isMobile ? "200px" : "250px") : "170px",
                           marginLeft: isSmallMonitor ? (isMobile ? "10px" : "40px") : "0",
                       }}>
            <Typography variant="h4" color="background.default" sx={{ fontSize: isMobile ? "24px" : "36px" }}>
                Какие данные мы собираем
            </Typography>
            <Box sx={{display: "flex", flexWrap: "wrap", gap: "30px 40px", zIndex: 1}}>
                {cardArray.map(({icon, headerText}, index) => (
                    <IconTextCard key={index} icon={icon} headerText={headerText} headerColor={"white"} backgroundColor={"#282B36"}
                                  backgroundIcon={"#7E2AEA"}
                                  sxHeader={{minHeight: ''}}
                                  sx={{
                                      maxWidth: isSmallMonitor ? "440px" : "560px",
                                      width: "100%",
                                      height: isMobile ? "104px" : "76px",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      gap: "30px",
                                  }}
                                     sxIcon={{width: "36px", height: "36px"}}/>

                ))}
            </Box>
        </SectionStyled>
    )
}
