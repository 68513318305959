import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Statistic from "./pages/create metrica/Statistic";
import CreateMetricaEmpty from "./pages/create metrica/createMetricaEmpty";
import MyMetrics from "./pages/create metrica/myMetrics";
import Landing from "./pages/landing/Landing";
import { clearUserData, setUser, setUserAccount, useUserStore } from "./stores/user";
import { clearAuthToken, getMessageFromFetchError, useUserAccountFetcher, useUserFetcher } from "@frontend/kitui";
import { enqueueSnackbar } from "notistack";
import SigninDialog from "./pages/auth/Signin";
import SignupDialog from "./pages/auth/Signup";
import SettingFunnel from "./pages/create metrica/settingFunnel";
import StatisticFunnel from "./pages/Funnel/StatisticFunnel";


export default function App() {
    const location = useLocation();
    const userId = useUserStore((state) => state.userId);
    const navigate = useNavigate();

    useUserFetcher({
        url: `https://hub.pena.digital/user/${userId}`,
        userId,
        onNewUser: setUser,
        onError: (error) => {
            const errorMessage = getMessageFromFetchError(error);
            if (errorMessage) {
                enqueueSnackbar(errorMessage);
                clearUserData();
                clearAuthToken();
            }
        },
    });

    useUserAccountFetcher({
        url: "https://hub.pena.digital/customer/account",
        userId,
        onNewUserAccount: setUserAccount,
        onError: (error) => {
            const errorMessage = getMessageFromFetchError(error);
            if (errorMessage) {
                enqueueSnackbar(errorMessage);
                clearUserData();
                clearAuthToken();
                navigate("/signin");
            }
        },
    });

    if (location.state?.redirectTo)
        return (
            <Navigate
                to={location.state.redirectTo}
                replace
                state={{ backgroundLocation: location }}
            />
        );

    return (
        <>
            {location.state?.backgroundLocation &&
                <Routes>
                    <Route path="/signin" element={<SigninDialog />} />
                    <Route path="/signup" element={<SignupDialog />} />
                </Routes>
            }
            <Routes location={location.state?.backgroundLocation || location}>
                <Route path="/" element={<Landing />} />
                <Route path="/signin" element={<Navigate to="/" replace state={{ redirectTo: "/signin" }} />} />
                <Route path="/signup" element={<Navigate to="/" replace state={{ redirectTo: "/signup" }} />} />
                <Route path="/create" element={<CreateMetricaEmpty />} />
                <Route path="/mymetrics" element={<MyMetrics />} />
                <Route path="/statistic" element={<Statistic />} />
                <Route path="/setting-funnel" element={<SettingFunnel/>} />
                <Route path="/statistic-funnel" element={<StatisticFunnel/>} />
            </Routes>
        </>
    );
}
