import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {IconButton, useTheme} from "@mui/material";


export default function ModalInstruction() {
    const theme = useTheme()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>

            <Button
                onClick={handleOpen}
                sx={{color: theme.palette.purple.main,
                padding: 0,
                textTransform: "none",
                textDecoration: "underline",
                textUnderlineOffset: "7px",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "20px",
            }}>Инструкция по установке</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: "540px",
                        width: "100%",
                        minHeight: "632px",
                        bgcolor: "background.paper",
                        borderRadius: "12px",
                        boxShadow: 24,
                        p: 0,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                            backgroundColor: theme.palette.background.default,
                            padding: "10px 9px 17px 20px",
                            borderRadius: "12px 12px 0px 0px"
                        }}>
                        <Typography variant={"h5"}>Установка</Typography>
                        <IconButton onClick={handleClose}> x
                            {/*<CloseIcon/>*/}
                        </IconButton>
                    </Box>

                    <Box sx={{padding: "15px 20px 0px"}}>
                        <Typography variant={"body2"} sx={{color: theme.palette.gray.main, fontWeight: 400}}>Язык: Python</Typography>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: theme.palette.background.default,
                            margin: "15px 20px",
                            padding: "20px",
                            borderRadius: "12px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px"
                        }}
                    >
                        <Typography variant="body2">Примечание</Typography>
                        <Typography variant="body2" fontWeight="400" sx={{color: theme.palette.gray.dark, lineHeight: "16px"}}>
                            Постоянный количественный рост и сфера нашей
                            активности обеспечивает широкому кругу (специалистов) участие в
                            формировании дальнейших направлений развития.
                        </Typography>
                    </Box>
                    <Box sx={{padding: "0 20px", display: "flex", flexDirection: "column", gap: "15px", color: theme.palette.gray.dark}}>
                        <Typography variant="body2" fontWeight="400" lineHeight="16px">Равным образом сложившаяся структура организации играет важную роль в
                            формировании позиций, занимаемых участниками в отношении поставленных задач.
                        </Typography>
                        <Typography variant="body2" fontWeight="400" lineHeight="16px">
                            Постоянный количественный рост и сфера нашей активности обеспечивает широкому кругу (специалистов)
                            участие в формировании дальнейших направлений развития.
                        </Typography>
                        <ol style={{paddingLeft: "25px", display: "flex", flexDirection: "column", gap: "15px",}}>
                            <li><Typography variant="body2" fontWeight="400" lineHeight="16px">
                                Значимость этих проблем настолько очевидна, что начало повседневной работы по формированию
                                позиции представляет собой интересный эксперимент проверки системы обучения кадров.
                            </Typography></li>
                            <li><Typography variant="body2" fontWeight="400" lineHeight="16px">
                                Равным образом постоянный количественный рост и сфера нашей активности способствует
                                подготовки и реализации дальнейших направлений развития.
                            </Typography></li>
                            <li><Typography variant="body2" fontWeight="400" lineHeight="16px">
                                Разнообразный и богатый опыт постоянный количественный рост и сфера нашей
                                активности играет важную роль в формировании систем массового участия.
                            </Typography></li>
                        </ol>



                    </Box>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                        padding: "20px"}}>
                        <Button variant="pena-contained-dark">Следующий этап</Button>
                    </Box>

                </Box>
            </Modal>
        </>
    );
}