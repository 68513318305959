import SectionStyled from "../../kit/section";
import {Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import CursorIcon from "../../icons/cursorIcon.svg";
import ExchangeIcon from "../../icons/exchangeIcon.svg";
import GroupIcon from "../../icons/GroupIcon.svg";
import PieIcon from "../../icons/pieIcon.svg";
import PlaneIcon from "../../icons/planeIcon.svg";
import SearchIcon from "../../icons/searchIcon.svg";
import IconTextCard from "../../kit/IconTextCard";

export default function Section4() {
    const theme = useTheme();
    const isSmallMonitor = useMediaQuery(theme.breakpoints.down(1200));
    const isMobile = useMediaQuery(theme.breakpoints.down(500));

    const cardArray = [
        {icon: PlaneIcon, headerText: "Совместим с Telegram", title: "Техт-заполнитель - это текст, который имеет"},
        {icon: SearchIcon, headerText: "Проще определить ЦА продукта", title: "Техт-заполнитель - это текст, который имеет"},
        {icon: GroupIcon, headerText: "Изучение поведения пользователей", title: "Техт-заполнитель - это текст, который имеет"},
        {icon: ExchangeIcon, headerText: "Оптимизация продукта под ЦА", title: "Техт-заполнитель - это текст, который имеет"},
        {icon: CursorIcon, headerText: "Удобная карта кликов", title: "Техт-заполнитель - это текст, который имеет"},
        {icon: PieIcon, headerText: "Сегментация аудитории", title: "Техт-заполнитель - это текст, который имеет"},

    ]
    return (
        <SectionStyled tag={'section'} bg={'#333647'} mwidth={'1160px'}
                       sxsect={{
                           minHeight: '763px',
                       }}
                       sxcont={{
                           display: 'flex',
                           flexDirection: 'column',
                           justifyContent: 'space-between',
                           alignItems: 'baseline',
                           padding: isSmallMonitor ? "60px 40px" : "80px 0",
                           gap: '30px'
                       }}>
            <Typography variant="h4" color="background.default" sx={{ fontSize: isMobile ? "24px" : "36px" }}>
                Преимущества системы
            </Typography>
            <Box sx={{display: "flex",
                flexWrap: "wrap", gap: "46px 106px", columnGap: isSmallMonitor ? "50px" : "100px", marginTop: "40px"}}>
                {cardArray.map(({icon, headerText, title}, index) => (
                    <IconTextCard key={index} icon={icon} headerText={headerText} text={title} sx={{maxWidth: "294px", minHeight: "196px", padding: 0}}
                                  textColor={"#9A9AAF"} headerColor={"white"} backgroundColor={""} sxHeader={{marginTop: "30px"}}/>

                    ))}
            </Box>
        </SectionStyled>
    )
}
