import { Box, Fab, Typography } from "@mui/material";
import { useState } from "react";
import CircleDoubleDown from "./CircleDoubleDownIcon";
import Chat from "./Chat";

export default function FloatingSupportChat() {
    const [isChatOpened, setIsChatOpened] = useState<boolean>(false);

    const animation = {
        "@keyframes runningStripe": {
            "0%": {
                left: "10%",
                backgroundColor: "transparent",
            },
            "10%": {
                backgroundColor: "#ffffff",
            },
            "50%": {
                backgroundColor: "#ffffff",
                transform: "translate(400px, 0)",
            },
            "80%": {
                backgroundColor: "#ffffff",
            },

            "100%": {
                backgroundColor: "transparent",
                boxShadow: "none",
                left: "100%",
            },
        },
    };
    return (
        <Box
            sx={{
                position: "fixed",
                right: "20px",
                bottom: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "clamp(200px, 100% - 40px, 454px)",
                zIndex: 10,
            }}
        >
            {isChatOpened && (
                <Chat
                    sx={{
                        alignSelf: "start",
                        width: "clamp(200px, 100%, 400px)",
                    }}
                />
            )}
            <Fab
                disableRipple
                sx={{
                    position: "relative",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    pl: "11px",
                    pr: !isChatOpened ? "15px" : "11px",
                    gap: "11px",
                    height: "54px",
                    borderRadius: "27px",
                    alignSelf: "end",
                    overflow: "hidden",
                    textTransform: "none",
                    "&:hover": {
                        background: "rgba(255, 255, 255, 0.7)",
                    },
                }}
                variant={"extended"}
                onClick={() => setIsChatOpened((prev) => !prev)}
            >
                {!isChatOpened && (
                    <Box
                        sx={{
                            position: "absolute",
                            bgcolor: "#FFFFFF",
                            height: "100px",
                            width: "25px",
                            animation: "runningStripe  linear 3s infinite",
                            transform: " skew(-10deg) rotate(70deg)  skewX(20deg) skewY(10deg)",
                            boxShadow: "0px 3px 12px rgba(126, 42, 234, 0.1)",
                            opacity: "0.4",
                            ...animation,
                        }}
                    />
                )}

                <CircleDoubleDown isUp={isChatOpened} />
                {!isChatOpened && <Typography sx={{ zIndex: "10000" }}>Задайте нам вопрос</Typography>}
            </Fab>
        </Box>
    );
}
