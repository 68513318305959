import {Box, Button, Typography, useTheme, Link} from "@mui/material";
import { useNavigate} from "react-router-dom";
import Section from "../../kit/section";
import HeaderFull from "../../kit/HeaderFull";
import MetricaCard from "./cardMetrica";
import FloatingSupportChat from "../../components/FloatingSupportChat/FloatingSupportChat";

export default function MyMetrics() {
    const theme = useTheme();
    return (
        <>
            <HeaderFull/>
            <Section bg={theme.palette.background.default} tag={'section'} mwidth={"1160px"} sxsect={{height: "100%"}}
                     sxcont={{
                         display: "flex",
                         flexDirection: "column",
                         alignItems: "flex-start",
                         height: "100%",
                         padding: "25px 0"
                     }}
            >
                <Typography variant='body1' sx={{color: theme.palette.gray.main, fontSize: "12px"}}>Кабинет проектов</Typography>
                <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Box sx={{width: "100%", display: "flex", flexDirection: "column"}}>
                        <Typography
                            variant="h4"
                            sx={{
                                color: "#000000",
                                mt: "20px",
                                mb: "35px",
                            }}
                        >
                            Мои метрики
                        </Typography>
                        <Button sx={{color: theme.palette.purple.main,
                            padding: 0,
                            textTransform: "none",
                            textDecoration: "underline",
                            textUnderlineOffset: "7px",
                            fontSize: "18px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            justifyContent: "start",
                            width: "215px"
                        }} >Моя сводная статистика </Button>
                    </Box>

                    <Button
                        variant="pena-contained-dark"
                    >
                        Создать +
                    </Button>
                </Box>
                <Box sx={{display: "flex", flexWrap: "wrap", gap: "40px", marginTop: "35px"}}>
                    <MetricaCard name={"Название"} />
                    <MetricaCard name={"Название"} />
                    <MetricaCard name={"Название"} />
                </Box>
                <FloatingSupportChat/>
            </Section>
        </>
    )
}
