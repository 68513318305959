import { makeRequest } from "@frontend/kitui";


const apiUrl = process.env.NODE_ENV === "production" ? "" : "https://hub.pena.digital";

export function logout() {
    return makeRequest<never, void>({
        url: apiUrl + "/auth/logout",
        method: "POST",
        useToken: true,
        withCredentials: true,
    });
}