import { Box, useTheme } from "@mui/material";


export default function ArrowDownIcon(props: any) {
    const theme = useTheme();

    return (
        <Box
            {...props}
            sx={{
                top: "25% !important",
                height: "24px",
                width: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M19.5 9L12 16.5L4.5 9" stroke={theme.palette.purple.main} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Box>
    );
}