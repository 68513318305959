import { useState, useRef } from "react";
import { Select as MuiSelect, MenuItem, Box, Typography, useTheme } from "@mui/material";
import classnames from "classnames";

import checkIcon from "../icons/check.svg";

import "./select.css";

import type { SelectChangeEvent } from "@mui/material";

type SelectProps = {
  items: string[];
  selectedItem: number;
  setSelectedItem?: (num: number) => void;
};

export const Select = ({ items, selectedItem, setSelectedItem }: SelectProps) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string>(items[selectedItem]);
  const ref = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const selectItem = (event: SelectChangeEvent<HTMLDivElement>) => {
    setCurrentValue(items[Number(event.target.value)]);
    // setSelectedItem(Number(event.target.value));
  };

  return (
    <Box sx={{maxWidth: "339px", width: "100%"}}>
      <Box
        sx={{
          zIndex: 1,
          position: "relative",
          width: "100%",
          height: "56px",
          padding: "16px 50px 16px 14px",
          color: theme.palette.purple.main,
          border: "2px solid #ffffff",
          borderRadius: "30px",
          background: "#EFF0F5",
          boxShadow: "0px 5px 40px #d2d0e194, 0px 2.76726px 8.55082px rgba(210, 208, 225, 0.4)",
            "&.MuiInputBase-root .opened": {
                transform: "rotate(180deg)"
            }
        }}
        onClick={() => ref.current?.click()}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {currentValue}
        </Typography>
        <Box component={"img"}
          src={checkIcon}
          alt="check"
          sx={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            height: "36px",
            width: "36px",
              "&.opened": {
                  transform: "rotate(180deg)",
                  position: "absolute",
                  top: "17%",
                  right: "10px",
                  height: "36px",
                  width: "36px",
              }
          }}
          className={classnames("select-icon", { opened })}
        />
      </Box>
      <MuiSelect
        ref={ref}
        className="select"
        value=""
        open={opened}
        MenuProps={{ disablePortal: true }}
        sx={{ width: "100%",
            "& .MuiInputBase-root.MuiOutlinedInput-root": {
            display: "block",
        },
            "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: 0
            },
            "&>div:first-child": {
            display: "none",
            },
            "&.MuiInputBase-root.MuiOutlinedInput-root .MuiSelect-icon": {
                display: "none",
            },
            "&.MuiMenu-root.MuiModal-root": {
            zIndex: 0
        },
            "&.MuiPaper-root.MuiMenu-paper": {
            paddingTop: "50px",
            marginTop: "-50px",
            borderRadius: "28px"
        }
        }}
        onChange={selectItem}
        onClick={() => setOpened((isOpened) => !isOpened)}
      >
        {items.map((item, index) => (
          <MenuItem key={item + index} value={index} sx={{ padding: "12px" }}>
            {item}
          </MenuItem>
        ))}
      </MuiSelect>
    </Box>
  );
};
