import {Box, Button, Container} from "@mui/material";
import PenaLogo from "../icons/logo/logo_Pena_black.svg";
import { AvatarButton, LogoutButton, clearAuthToken, getMessageFromFetchError } from "@frontend/kitui";
import { enqueueSnackbar } from "notistack";
import { clearUserData } from "../stores/user";
import { logout } from "../api/auth";
import { Link, useNavigate } from "react-router-dom";


export default function HeaderFull() {
    const navigate = useNavigate();

    async function handleLogoutClick() {
        try {
            await logout();
            clearAuthToken();
            clearUserData();
            navigate("/");
        } catch (error: any) {
            const message = getMessageFromFetchError(error, "Не удалось выйти");
            if (message) enqueueSnackbar(message);
        }
    }

    return (
        <Container
            component="nav"
            disableGutters
            maxWidth={false}
            sx={{
                px: "16px",
                display: "flex",
                height: "80px",
                alignItems: "center",
                gap: "60px",
                bgcolor: "white",
                borderBottom: "1px solid #E3E3E3",
            }}
        >
            <img src={PenaLogo} alt="pena logo" />
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <Button component={Link} to="/create">Создание</Button>
                <Button component={Link} to="/mymetrics">Мои метрики</Button>
                <Button component={Link} to="/statistic">Статистика</Button>
                <Button component={Link} to="/setting-funnel">Настройки воронки</Button>
                <Button component={Link} to="/statistic-funnel" >Этапы воронки</Button>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    ml: "auto",
                    gap: "20px",
                }}
            >
                <AvatarButton />
                <LogoutButton onClick={handleLogoutClick} />
            </Box>
        </Container>
    );
}
