
import { Typography, Box, SxProps, Theme, useTheme, useMediaQuery } from "@mui/material";

interface Props {
  icon: string;
  headerText: string;
  headerHeight?: string;
  text?: string;
  textColor?: string;
  headerColor: string;
  backgroundColor: string;
  sx?: SxProps<Theme>;
  sxHeader?: SxProps<Theme>;
  backgroundIcon?: string;
  sxIcon?: SxProps<Theme>;
}

export default function IconTextCard({
  icon,
  headerText,
  headerHeight = "56px",
  text,
  textColor,
  backgroundColor,
  sx,
  sxHeader,
  headerColor,
  backgroundIcon,
  sxIcon
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        borderRadius: "12px",
        backgroundColor,
        p: "20px",
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
        <Box
            component="div"
            sx={{
                height: "59px",
                width: "59px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "6px",
                backgroundColor: backgroundIcon,
                ...sxIcon
            }}
        >
            <img src={icon} alt="svg" />
        </Box>

      <Typography
        variant="h5"  
        sx={{
          mt: "14px",
          mb: "10px",
          minHeight: isMobile ? "" : headerHeight,
          color: headerColor,
          ...sxHeader,
        }}
      >
        {headerText}
      </Typography>
      {text && (
          <Typography variant="body1" sx={{ color: textColor, flexGrow: 1 }}>
              {text}
          </Typography>
      )}
    </Box>
  );
}
