import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import LinkIcon from "../../icons/LinkIcon";
import PencilIcon from "../../icons/PencilIcon";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

interface Props {
    name: string;
    openCount?: number;
    onClickDelete?: () => void;
    onClickEdit?: () => void;
}

export default function MetricaCard({ name, openCount = 0, onClickDelete, onClickEdit }: Props) {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                width: "560px",
                height: "280px",
                p: "20px",
                borderRadius: "12px",
                boxSizing: "border-box",
                boxShadow: `0px 100px 309px rgba(210, 208, 225, 0.24),
                            0px 41.7776px 129.093px rgba(210, 208, 225, 0.172525), 
                            0px 22.3363px 69.0192px rgba(210, 208, 225, 0.143066),
                            0px 12.5216px 38.6916px rgba(210, 208, 225, 0.12),
                            0px 6.6501px 20.5488px rgba(210, 208, 225, 0.0969343),
                            0px 2.76726px 8.55082px rgba(210, 208, 225, 0.0674749)`,
            }}
        >
            <Typography variant="h5">{name}</Typography>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: "10px",
                    gap: "10px",
                }}
            >
                <LinkIcon bgcolor="#EEE4FC" color={theme.palette.purple.main} />
                <Typography color={theme.palette.gray.dark}>быстрая ссылка ...</Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    mt: "32px",
                    mr: "22px",
                }}
            >
                <Box sx={{ flex: "1 1 0" }}>
                    <Typography variant="h5">{openCount}</Typography>
                    <Typography color={theme.palette.gray.dark}>Кликов</Typography>
                </Box>
                <Box sx={{ flex: "1 1 0" }}>
                    <Typography variant="h5">{openCount}</Typography>
                    <Typography color={theme.palette.gray.dark}>Открытий</Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    mt: "auto",
                    display: "flex",
                    gap: "20px",
                }}
            >
                <Button
                    variant="pena-contained-dark"
                >Статистика</Button>
                <Button
                    variant="pena-outlined-purple"
                    startIcon={<PencilIcon />}
                    onClick={onClickEdit}
                    sx={{
                        px: "20px",
                        "&:active > span:first-of-type > div": {
                            color: "white",
                        }
                    }}
                >
                    Редактировать
                </Button>
                <IconButton
                    sx={{
                        color: theme.palette.purple.main,
                        ml: "auto",
                    }}
                    onClick={onClickDelete}
                >
                    <MoreHorizIcon sx={{ transform: "scale(1.75)" }} />
                </IconButton>
            </Box>
        </Box >
    );
}
