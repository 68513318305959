import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SectionStyled from './section';
import logotipBlack from '../icons/logo/logo_Pena.svg';
import { Link, useLocation } from 'react-router-dom';
import { useUserStore } from '../stores/user';
const buttonMenu = [
    { name: "Главная", url: "/" },
    { name: "Создать", url: "/create" },
    { name: "Мои метрики", url: "/mymetrics" },
    { name: "Статистика", url: "/statistic" },
    { name: "Настройки воронки", url: "/setting-funnel" },
    { name: "Этапы воронки", url: "/statistic-funnel" },
    ];



export default function Header() {
    const [select, setSelect] = React.useState(0);
    const user = useUserStore(state => state.user);
    const location = useLocation();

    return (
        <SectionStyled
            tag={'header'}
            bg={'#262835'}
            mwidth={'1160px'}
            sxsect={{
                minHeight: '80px',
                borderBottom: '1px solid #434556',
            }}
            sxcont={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 10px'
            }}>
            <Box>
                <img src={logotipBlack} alt="logotip" />
            </Box>
            <Box
                sx={{
                    maxWidth: '595px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',

                }}
            >
                {buttonMenu.map(({ name, url }, index) => (
                    <Button
                        variant="pena-navitem-dark"
                        component={Link}
                        to={url}
                        key={index}
                        onClick={() => {
                            setSelect(index);
                        }}
                        sx={{
                            fontSize: "16px",
                            color: select === index ? '#7E2AEA' : 'white',
                            textTransform: 'none',
                        }}
                    >{name}</Button>
                ))}
            </Box>
            <Button
                component={Link}
                to={user ? "/mymetrics" : "/signin"}
                state={user ? undefined : { backgroundLocation: location }}
                variant="pena-outlined-purple"
                sx={{ px: "4px" }}
            >Личный кабинет</Button>
        </SectionStyled>
    );
}
