import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { theme } from './utils/theme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </SnackbarProvider>
        </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);
