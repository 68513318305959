import {Box, Button, Typography, useTheme} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import Section from "../../kit/section";
import HeaderFull from "../../kit/HeaderFull";
import FloatingSupportChat from "../../components/FloatingSupportChat/FloatingSupportChat";


export default function CreateMetricaEmpty() {
    const theme = useTheme();
    return (
        <>
            <HeaderFull/>
            <Section bg={theme.palette.background.default} tag={'section'} mwidth={"1160px"} sxsect={{height: "calc(100vh - 80px)"}}
                     sxcont={{
                         display: "flex",
                         flexDirection: "column",
                         alignItems: "flex-start",
                         height: "100%",
                         justifyContent: "space-between",
                         padding: "25px 0"
                     }}
            >
                <Box>
                    <Typography variant='body1' sx={{color: theme.palette.gray.main, fontSize: "12px"}}>Кабинет проектов</Typography>
                    <Typography
                        variant="h4"
                        sx={{
                            color: "#000000",
                            mt: "20px",
                            mb: "30px",
                        }}
                    >
                        Создайте свою первую метрику
                    </Typography>
                    <Button
                        sx={{padding: "10px 43px"}}
                        variant="pena-contained-dark"
                    >
                        Создать +
                    </Button>
                </Box>
                <Box>
                    <FloatingSupportChat/>
                </Box>
            </Section>
        </>

    );
}
