import SectionStyled from "../../kit/section";
import {Typography, Box, useTheme, useMediaQuery} from "@mui/material";
import React from "react";
import backIcon from "../../images/back.png";
import backIcon2 from "../../images/back2.png";
import backWideIcon from "../../images/back-wide.png";
import backWideIcon2 from "../../images/back-wide2.png";

export default function Section5() {
    const theme = useTheme();
    const isSmallMonitor = useMediaQuery(theme.breakpoints.down(1200));
    const isTablet = useMediaQuery(theme.breakpoints.down(800));
    const isMobile = useMediaQuery(theme.breakpoints.down(500));
    return (
        <SectionStyled tag={'section'} bg={"#252734"} mwidth={'1160px'}
                       sxsect={{
                           minHeight: '712px',
                           paddingBottom: '44px'
                       }}
                       sxcont={{
                           display: 'flex',
                           flexWrap: "wrap",
                           padding: isSmallMonitor ? "60px 40px" : "100px 0",
                           gap: '30px 160px',
                       }}>
            <Box
                sx={{
                    width: "500px",
                    height: "220px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "70px"
            }}>
                <Typography variant="h4" color="background.default"
                            sx={{
                                maxWidth: "354px",
                                fontSize: isMobile ? "24px" : "36px",
                }}>
                    Получайте сводку в удобном формате
                </Typography>
                <Typography variant="body1">
                    Текст-заполнитель —
                    это текст, который имеет некоторые характеристики реального письменного
                </Typography>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    maxWidth: isSmallMonitor ? "800px" : "500px",
                    height: "300px",
                    borderRadius: "12px",
                    background: isSmallMonitor
                        ? `url(${backWideIcon2}) 150px 0 / auto no-repeat`
                        : `url(${backIcon2}) bottom / auto no-repeat`,
                    backgroundColor: "#434657",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                }}
            >
                <Typography variant="h5" sx={{maxWidth: "205px"}}>Продуманный интерфейс</Typography>
                <Typography variant="body1" sx={{maxWidth: "205px", display: "block"}}>Текст-заполнитель —
                    это текст, который имеет некоторые характеристики реального</Typography>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    maxWidth: isSmallMonitor ? "800px" : "500px",
                    height: "300px",
                    borderRadius: "12px",
                    background: isSmallMonitor
                        ? `url(${backWideIcon}) 0 -150px / auto no-repeat`
                        : `url(${backIcon}) bottom / auto no-repeat`,
                    backgroundColor: "#434657",
                    padding: "20px",
                    display: "flex",
                    gap: "20px",
                    marginTop: isSmallMonitor ? "0" : "30px",
                    marginLeft: isSmallMonitor ? (isTablet ? "0" : "120px") : "0",
                }}
            >
                <Typography variant="h5" sx={{maxWidth: "205px"}}>Все на одной странице</Typography>
                <Typography variant="body1" sx={{maxWidth: "205px", display: "block"}}>Текст-заполнитель —
                    это текст, который</Typography>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    maxWidth: isSmallMonitor ? "800px" : "500px",
                    height: "300px",
                    borderRadius: "12px",
                    background: isSmallMonitor
                        ? `url(${backWideIcon2}) 150px 0 / auto no-repeat`
                        : `url(${backIcon2}) bottom / auto no-repeat`,
                    backgroundColor: "#434657",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    marginTop: isSmallMonitor ? "0" : "110px",
                }}
            >
                <Typography variant="h5" sx={{maxWidth: "205px"}}>Настройка отображения</Typography>
                <Typography variant="body1" sx={{maxWidth: "205px", display: "block"}}>Текст-заполнитель —
                    это текст, который имеет некоторые характеристики реального письменного</Typography>
            </Box>

        </SectionStyled>
    )
}
