import * as React from 'react';
import {Box, Button, Typography, useTheme, Paper, Pagination, IconButton,} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Section from "../../kit/section";
import HeaderFull from "../../kit/HeaderFull";
import CustomizedTables from "./StatisticTable";
import Period from "./periodDisplay";
import FloatingSupportChat from "../../components/FloatingSupportChat/FloatingSupportChat";

export default function Statistic() {
    const theme = useTheme();
    return (
        <>
            <HeaderFull />
            <Section bg={theme.palette.background.default} tag={'section'} mwidth={"1160px"} sxsect={{ height: "100%" }}
                sxcont={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    height: "100%",
                    padding: "25px 0",
                    gap: "40px"
                }}
            >
                <Typography variant='body1' sx={{ color: theme.palette.gray.main, fontSize: "12px" }}>Кабинет
                    проектов</Typography>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography
                        variant="h4"
                        sx={{
                            color: "#000000",
                        }}
                    >
                        Статистика
                    </Typography>
                </Box>
                <Period isStatFunnel={false}/>
                <CustomizedTables />
                <Box sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",}}>
                    <Pagination
                        variant="pena-pagination"
                        count={5}
                        shape="rounded"
                    />
                </Box>

                <FloatingSupportChat />
            </Section>
        </>
    );
}
