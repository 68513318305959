import { Box, useTheme, useMediaQuery } from "@mui/material";
import Bulb from "./bulbs3.svg";

export const Bubbles3 = () => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    return (
        <Box
            sx={{
                position: "relative",
                height: isMobile ? "60px" : "100px",
                backgroundColor: "#333647",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "1440px",
                    margin: "0 auto",
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: isMobile ? "-85px" : "-45px",
                        transform: isMobile ? "scale(0.6)" : null,
                        left: isTablet ? (isMobile ? "-130px" : "-100px") : "0",
                        zIndex: 1,
                        margin: "0 auto",
                    }}
                >
                    <img src={Bulb} alt="bubble" />
                </Box>
            </Box>
        </Box>
    );
};
