import * as React from "react";
import Box from "@mui/material/Box";
import { SxProps, Theme, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface Props {
  sxsect?: SxProps<Theme>;
  mwidth: string;
  padding?: string;
  tag: React.ElementType;
  bg: string;
  children: React.ReactNode;
  sxcont?: SxProps<Theme>;
}


export default function Section(props: Props) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
    const isMinTablet = useMediaQuery(theme.breakpoints.down(886));
    const isRubberSmall = useMediaQuery(theme.breakpoints.down(500));

  return (
    <Box
      component={props.tag}
      backgroundColor={props.bg}
      sx={{
        width: "100%",
        fontFamily: "Rubik",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.sxsect,
      }}
    >
      <Box
        maxWidth={props.mwidth}
        sx={{
          width: "100%",
          padding: isTablet ? "0 40px" : "0 20px",
            [theme.breakpoints.down(600)]: {
                padding: "0 16px",
            },
          ...props.sxcont,
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
