import Header from '../../kit/header';
import Hero from '../../pages/landing/Hero';
import Footer from '../../kit/footer';
import React from 'react';
import {Bubbles} from "./bubbles";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import Section6 from "./section6";
import Section7 from "./section7";
import {Bubbles2} from "./bubbles2";
import {Bubbles3} from "./bubbles3";
import { Box } from '@mui/material';
import FloatingSupportChat from '../../components/FloatingSupportChat/FloatingSupportChat';

export default function Landing () {
    return(
        <Box sx={{ color: "white", position: "relative", overflow: "hidden"}}>
            <Header/>
            <Hero/>
            <Bubbles/>
            <Section2/>
            <Section3/>
            <Section4/>
            <Section5/>
            <Bubbles2/>
            <Section6/>
            <Section7/>
            <Bubbles3/>
            <Footer/>
            <FloatingSupportChat />
        </Box>
    )
}
