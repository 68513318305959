import { Box, useTheme, useMediaQuery } from "@mui/material";
import Bulb from "./bulbs 1.svg"


export const Bubbles = () => {
    const theme = useTheme();
    const isSmallMonitor = useMediaQuery(theme.breakpoints.down(1200));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    return (
      <Box sx={{
          position: "relative",
          width: "100%",
          maxWidth: "1440px",
          margin: "0 auto",
      }}
      >
          <img src={Bulb}
               alt="bubbles"
               style={{
                   position: "absolute",
                   right: isSmallMonitor ? (isMobile ? "-380px" : "-120px") : "0",
                   bottom: "-335px",
                   transform: isSmallMonitor
                       ? isMobile
                           ? "scale(0.35)"
                           : "scale(0.9)"
                       : "unset",
               }}
          />
      </Box>
    )
}
