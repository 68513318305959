import {Box, Typography, useTheme} from "@mui/material";

interface Props {
    isSelf: boolean;
    text: string;
    messageBackgroundColor: string
}

export default function MassageBot({ isSelf, text, messageBackgroundColor }: Props) {
    const theme = useTheme();


    return (
        <Box
            sx={{
                display: "flex",
                alignSelf: isSelf ? "end" : "start",
                gap: "9px",
                pl: isSelf ? undefined : "8px",
                pr: isSelf ? "8px" : undefined,
            }}
        >
            <Box
                sx={{
                    backgroundColor: messageBackgroundColor,
                    border: `1px solid ${theme.palette.gray.main}`,
                    // order: isSelf ? 2 : 1,
                    p: "20px",
                    borderRadius: "8px",
                    maxWidth: "464px",
                    width: "max-content",
                    color: (isSelf) ? theme.palette.gray.dark : "white",
                    position: "relative",
                }}
            >
                <svg
                    style={{
                        position: "absolute",
                        top: "-1px",
                        right: isSelf ? "-8px" : undefined,
                        left: isSelf ? undefined : "-8px",
                        transform: isSelf ? undefined : "scale(-1, 1)",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                    fill="none"
                >
                    <path d="M0.5 0.5L15.5 0.500007
                                C10 0.500006 7.5 8 7.5 7.5H7.5H0.5V0.5Z"
                          fill={messageBackgroundColor}
                          stroke={theme.palette.gray.main}
                    />
                    <rect y="1" width="8" height="8" fill={messageBackgroundColor} />
                </svg>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: "18px",
                        lineHeight: "19px",
                    }}
                >{text}</Typography>
            </Box>
        </Box>
    )
}