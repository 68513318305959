import * as React from 'react';
import {Paper, Box, Typography, useTheme, SxProps, Theme} from '@mui/material';


function createData(
    date: string,
    bot: number,
    unTransitions: number,
    transitions: number,
    sources: string,
    sales: number,
    salesAmount: string,

) {
    return { date, bot, unTransitions, transitions, sources, sales, salesAmount };
}



const rows = [
    createData('28.05.2022', 7, 2, 47, '90%/10%', 3, '1 190 руб.' ),
    createData('28.05.2022', 42, 29, 301, '75%/25%', 517, '2 105 190 руб.'),
    createData('08.04.2022', 11, 6, 72, '100%/0%', 44, '52 081 руб.'),
    createData('28.05.2022', 4, 2, 47, '90%/10%', 3, '1 190 руб.'),
    createData('18.03.2022', 1, 0, 35, '75%/25%', 0, '0 руб.'),
];

const RowsTable = (props: { children: React.ReactNode, rowSx?: SxProps<Theme> }) => {
    return (
        <Box
            sx={{
                maxWidth: "169px",
                width: "100%",
                borderRight: "1px solid #9A9AAF",
                fontSize: "18px",
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                ...props.rowSx
        }}>{props.children}</Box>
    )
}

const HeadTable = (props: { children: React.ReactNode, headSx?: SxProps<Theme> }) => {
    return (
        <Box
            sx={{
                maxWidth: "169px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                color: "#ffffff",
                ...props.headSx
            }}>{props.children}</Box>
    )
}

export default function CustomizedTables() {
    const theme = useTheme();
    return (
        <Paper sx={{borderRadius: "12px", overflow: "hidden", width: "100%", boxShadow: "0px 2.767256498336792px 8.550822257995605px 0px rgba(210, 208, 225, 0.07), 0px 6.650102138519287px 20.54881477355957px 0px rgba(210, 208, 225, 0.10), 0px 12.521552085876465px 38.69159698486328px 0px rgba(210, 208, 225, 0.12), 0px 22.3363094329834px 69.01919555664062px 0px rgba(210, 208, 225, 0.14), 0px 41.777610778808594px 129.0928192138672px 0px rgba(210, 208, 225, 0.17), 0px 100px 309px 0px rgba(210, 208, 225, 0.24)" }}>
            <Box sx={{display: "flex", padding: "25px 0", backgroundColor: theme.palette.gray.main, height: "72px"}}>
                <Box sx={{maxWidth: "141px", width: "100%", display: "flex", justifyContent: "center", alignItems: 'center', color: "#ffffff"}}>
                    <Typography sx={{width: "113px", textAlign: "center", fontWeight: 500}}>Дата</Typography></Box>
                <HeadTable><Typography sx={{width: "113px", textAlign: "center", fontWeight: 500}}>Добавили бота в группу</Typography></HeadTable>
                <HeadTable><Typography sx={{width: "113px", textAlign: "center", fontWeight: 500}}>Уникальные переходы</Typography></HeadTable>
                <HeadTable><Typography sx={{width: "113px", textAlign: "center", fontWeight: 500}}>Общее кол-во переходов</Typography></HeadTable>
                <HeadTable headSx={{flexDirection: "column"}}><Typography sx={{width: "170px", textAlign: "center", fontWeight: 500}}>Источник переходов</Typography><Typography sx={{fontSize: "12px", fontWeight: 400}}>Мобильная/веб версия</Typography></HeadTable>
                <HeadTable><Typography >Кол-во продаж</Typography></HeadTable>
                <HeadTable><Typography>Сумма продаж</Typography></HeadTable>

            </Box>
            {rows.map((row) => (
                <Box sx={{
                    display: "flex",
                    height: "72px",
                    borderBottom: "1px solid #9A9AAF",
                    '&:last-child': {
                        border: "none"
                    },
                    '&:nth-child(even)': {
                        backgroundColor: theme.palette.gray.light
                    }
                }}>
                    <RowsTable rowSx={{maxWidth: "141px", color: theme.palette.purple.main, fontWeight: 500, fontSize: "18px"}}>{row.date}</RowsTable>
                    <RowsTable>{row.bot}</RowsTable>
                    <RowsTable>{row.unTransitions}</RowsTable>
                    <RowsTable>{row.transitions}</RowsTable>
                    <RowsTable>{row.sources}</RowsTable>
                    <RowsTable>{row.sales}</RowsTable>
                    <RowsTable rowSx={{maxWidth: "174px", borderRight: "none", fontWeight: 500}}>{row.salesAmount}</RowsTable>
                </Box>
            ))}
        </Paper>
    );
}