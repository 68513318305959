import { Box, useTheme } from "@mui/material";


export default function PencilIcon() {
    const theme = useTheme();
    
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: theme.palette.purple.main,
                transition: `color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M8.69063 20.25H4.5C4.30109 20.25 4.11033 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V15.3094C3.74966 15.212 3.76853 15.1155 3.80553 15.0254C3.84253 14.9353 3.89694 14.8534 3.96563 14.7844L15.2156 3.53437C15.2854 3.46351 15.3686 3.40724 15.4603 3.36882C15.5521 3.33041 15.6505 3.31063 15.75 3.31063C15.8495 3.31063 15.9479 3.33041 16.0397 3.36882C16.1314 3.40724 16.2146 3.46351 16.2844 3.53437L20.4656 7.71562C20.5365 7.78541 20.5928 7.8686 20.6312 7.96034C20.6696 8.05208 20.6894 8.15054 20.6894 8.25C20.6894 8.34946 20.6696 8.44792 20.6312 8.53966C20.5928 8.6314 20.5365 8.71459 20.4656 8.78437L9.21563 20.0344C9.1466 20.1031 9.06469 20.1575 8.9746 20.1945C8.88452 20.2315 8.78802 20.2503 8.69063 20.25V20.25Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.75 6L18 11.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.375 8.625L6.375 17.625" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.95312 20.2031L3.79688 15.0469" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Box>
    );
}
