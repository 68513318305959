import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { User, UserAccount } from "@frontend/kitui";
import { produce } from "immer";


interface UserStore {
    userId: string | null;
    user: User | null;
    userAccount: UserAccount | null;
}

const initialState: UserStore = {
    userId: null,
    user: null,
    userAccount: null,
};

export const useUserStore = create<UserStore>()(
    persist(
        devtools(
            (set, get) => initialState,
            {
                name: "User",
                enabled: process.env.NODE_ENV === "development",
                trace: true,
            }
        ),
        {
            version: 2,
            name: "user",
            storage: createJSONStorage(() => localStorage),
            partialize: (state) => ({
                userId: state.userId,
                user: state.user,
            }),
            migrate: (persistedState, version) => ({
                ...(persistedState as UserStore),
                user: null,
            }),
        }
    )
);

export const setUserId = (userId: UserStore["userId"]) => useUserStore.setState({ userId });

export const setUser = (user: UserStore["user"]) => useUserStore.setState({ user });

export const clearUserData = () => useUserStore.setState({ ...initialState });

export const setUserAccount = (user: UserAccount) =>
  useUserStore.setState(
    produce<UserStore>((state) => {
      state.userAccount = user;
    }),
    false,
    {
      type: "setUserAccount",
      payload: user,
    }
  );
