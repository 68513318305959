import {
    Box,
    Typography,
    useTheme,
    Paper,
    Button,
    Pagination,
} from "@mui/material";
import React from "react";
import Section from "../../kit/section";
import HeaderFull from "../../kit/HeaderFull";
import Period from "./periodDisplay";
import CustomizedTables from "./StatisticTable";
import {Select} from "../../components/Select";
import number1Icon from "../../icons/number1.svg";
import number2Icon from "../../icons/number2.svg";
import ModalInstruction from "./modalInstruction";


export default function SettingFunnel() {
    const theme = useTheme();


    return (
        <>
            <HeaderFull/>
            <Section bg={theme.palette.gray.light} tag={'section'} mwidth={"1160px"} sxsect={{height: "100%"}}
                     sxcont={{
                         display: "flex",
                         flexDirection: "column",
                         alignItems: "flex-start",
                         height: "100%",
                         padding: "25px 0",
                         gap: "20px"
                     }}
            >
                <Typography sx={{color: theme.palette.gray.main, fontSize: "12px"}}>Настройка воронки</Typography>
                {/* <Typography variant='t1' sx={{color: theme.palette.gray.main, fontSize: "12px"}}>Настройка воронки</Typography> */}

                    <Typography
                        variant="h2"
                        sx={{
                            color: "#000000",
                            fontSize: "36px",
                            lineHeight: 'normal'
                        }}
                    >
                        Настройка воронки
                    </Typography>
                <Box sx={{display: "flex", justifyContent: "space-between", width: "100%", marginTop: "10px"}}>
                    <Paper sx={{width: "560px", height: "194px",
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "15px",
                        borderRadius: "12px",
                        border: "1px solid #FFF",
                        background: "rgba(255, 255, 255, 0.50)",
                        boxShadow: "0px 2.76726px 8.55082px 0px rgba(210, 208, 225, 0.07), 0px 6.6501px 20.54881px 0px rgba(210, 208, 225, 0.10), 0px 12.52155px 38.6916px 0px rgba(210, 208, 225, 0.12), 0px 22.33631px 69.0192px 0px rgba(210, 208, 225, 0.14), 0px 41.77761px 129.09282px 0px rgba(210, 208, 225, 0.17), 0px 100px 309px 0px rgba(210, 208, 225, 0.24)",
                    }}>
                        <img
                            src={number1Icon}
                            alt="check"/>
                        <Typography variant="h5">Выберите язык</Typography>

                        <Select items={['Java', 'Python', 'C#']} selectedItem={1} />
                    </Paper>
                    <Paper sx={{width: "560px", height: "194px",
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "15px",
                        borderRadius: "12px",
                        border: "1px solid #FFF",
                        background: "rgba(255, 255, 255, 0.50)",
                        boxShadow: "0px 2.76726px 8.55082px 0px rgba(210, 208, 225, 0.07), 0px 6.6501px 20.54881px 0px rgba(210, 208, 225, 0.10), 0px 12.52155px 38.6916px 0px rgba(210, 208, 225, 0.12), 0px 22.33631px 69.0192px 0px rgba(210, 208, 225, 0.14), 0px 41.77761px 129.09282px 0px rgba(210, 208, 225, 0.17), 0px 100px 309px 0px rgba(210, 208, 225, 0.24)",
                    }}>
                        <img
                            src={number2Icon}
                            alt="check"/>
                        <Typography variant="h5">Изучите инструкцию</Typography>
                        <Box marginTop="12px">
                            <ModalInstruction/>
                        </Box>

                    </Paper>
                </Box>
                <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", flexDirection: 'column', marginTop: "44px"}}>
                    <Typography
                        variant="h2"
                        sx={{
                            color: "#000000",
                            fontSize: "36px",
                            lineHeight: 'normal'
                        }}
                    >
                        Статистика
                    </Typography>
                    <Typography>по достижению целей</Typography>
                </Box>
                <Box sx={{marginTop: "25px", width: "100%"}}>
                    <Period isStatFunnel={false} />
                </Box>

                <CustomizedTables/>
                <Box sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",}}
                >
                    <Pagination
                        variant="pena-pagination"
                        count={5}
                        shape="rounded"/>
                </Box>


            </Section>
        </>
    )
}