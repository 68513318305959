import { useTheme } from "@mui/material";


export default function CalendarIcon() {
    const theme = useTheme();

    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Calendar">
                <rect id="Rectangle 2" x="1" y="2.50098" width="18" height="18" rx="5" stroke="#7E2AEA" stroke-width="1.5"/>
                <path id="Line" d="M1 7.50095H19" stroke="#7E2AEA" stroke-width="1.5" stroke-linejoin="round"/>
                <path id="Line_2" d="M14.5 1.00095L14.5 4.00095" stroke="#7E2AEA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Line_3" d="M5.5 1.00095L5.5 4.00095" stroke="#7E2AEA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <g id="Group 220">
                    <g id="Group 218">
                        <path id="Line_4" d="M4.5 11.501H5.5" stroke="#7E2AEA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path id="Line_5" d="M9.5 11.501H10.5" stroke="#7E2AEA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path id="Line_6" d="M14.5 11.501H15.5" stroke="#7E2AEA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <g id="Group 219">
                        <path id="Line_7" d="M4.5 15.501H5.5" stroke="#7E2AEA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path id="Line_8" d="M9.5 15.501H10.5" stroke="#7E2AEA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path id="Line_9" d="M14.5 15.501H15.5" stroke="#7E2AEA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </g>
            </g>
        </svg>
    );
}