import { Box, useTheme, useMediaQuery } from "@mui/material";
import Bulb from "./bulbs2.svg"


export const Bubbles2 = () => {
    const theme = useTheme();
    const isSmallMonitor = useMediaQuery(theme.breakpoints.down(1200));
    const isTablet = useMediaQuery(theme.breakpoints.down(960));

    return (
        <Box sx={{ position: "relative", height: "100%" }}>
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "1440px",
                    margin: "0 auto",
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        bottom: isTablet ? "-165px" : "-75px",
                        right: isSmallMonitor ? (isTablet ? "-230px" : "-340px") : "0px",
                        transform: isTablet ? "scale(0.6)" : null,
                    }}
                >
                    <img src={Bulb} alt="bubble" />
                </Box>
            </Box>
        </Box>
    )
}
