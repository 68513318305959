import { Box, useTheme } from "@mui/material";


interface Props {
    color: string;
    bgcolor: string;
}

export default function LinkIcon({ color, bgcolor }: Props) {
    const theme = useTheme();

    return (
        <Box
            sx={{
                bgcolor,
                borderRadius: "6px",
                height: "36px",
                width: "36px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M8.8219 15.1781L15.1781 8.8125" stroke={theme.palette.purple.main} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.5938 16.7719L10.9406 19.425C10.5227 19.843 10.0265 20.1745 9.48035 20.4007C8.93425 20.6269 8.34893 20.7434 7.75783 20.7434C6.56404 20.7434 5.41915 20.2691 4.57502 19.425C3.73088 18.5809 3.25665 17.436 3.25665 16.2422C3.25665 15.0484 3.73088 13.9035 4.57502 13.0594L7.22814 10.4062" stroke={theme.palette.purple.main} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.7719 13.5938L19.425 10.9406C20.2691 10.0965 20.7434 8.9516 20.7434 7.75781C20.7434 6.56403 20.2691 5.41914 19.425 4.575C18.5809 3.73087 17.436 3.25664 16.2422 3.25664C15.0484 3.25664 13.9035 3.73087 13.0594 4.575L10.4062 7.22813" stroke={theme.palette.purple.main} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Box>
    );
}