import SectionStyled from "../../kit/section";
import { useTheme, useMediaQuery } from "@mui/material";
import {Typography} from "@mui/material";
import React from "react";
import IndexTextCard from "../../kit/IndexTextCard";

export default function Section7() {
    const theme = useTheme();
    const isSmallMonitor = useMediaQuery(theme.breakpoints.down(1200));
    return (
        <SectionStyled tag={'section'} bg={"#252734"} mwidth={'1160px'}
                       sxsect={{
                           minHeight: '538px',

                           paddingBottom: '44px'
                       }}
                       sxcont={{
                           display: 'flex',
                           flexWrap: 'wrap',
                           justifyContent: 'space-between',
                           alignItems: 'baseline',
                           padding: '90px 0 50px 0',
                           gap: '40px 10px'
                       }}>
            <IndexTextCard indexText={"5"} headerText={"лет на рынке"}/>
            <IndexTextCard indexText={">10"} headerText={"типов данных о пользователях"}/>
            <IndexTextCard indexText={"3 651"} headerText={"довольных клиентов"}/>
            <IndexTextCard indexText={"7"} headerText={"дней бесплатного пользования"}/>
            <IndexTextCard indexText={"24/7"} headerText={"с вами служба поддержки"}/>
            <IndexTextCard indexText={"1 000"} headerText={"рублей в месяц минимальный тариф"}/>
        </SectionStyled>
    )
}
