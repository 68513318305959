import SectionStyled from "../../kit/section";
import {Typography} from "@mui/material";
import React from "react";
import { useTheme, useMediaQuery } from "@mui/material";

export default function Section3() {
    const theme = useTheme();
    const isSmallMonitor = useMediaQuery(theme.breakpoints.down(1200));
    const isTablet = useMediaQuery(theme.breakpoints.down(930));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    return (
        <SectionStyled tag={'section'} bg={"#252734"} mwidth={'1160px'}
                       sxsect={{
                           minHeight: isTablet ? "350px" : "284px",
                       }}
                       sxcont={{
                           display: 'flex',
                           flexDirection: 'column',
                           justifyContent: 'space-between',
                           alignItems: 'baseline',
                           padding: isSmallMonitor ? (isMobile ? "30px 16px" : "0 40px") : "0",
                           gap: '30px'
                       }}>
            <Typography
                variant="body1"
                color={"#9A9AAf"}
                maxWidth={"549px"}
                sx={{
                    maxWidth: isSmallMonitor ? (isTablet ? "100%" : "320px") : "549px",
                    marginTop: isTablet ? "175px" : 0,
                }}
            >
                <span style={{color: "white"}}>Pena API</span>
                — это инструмент аналитики, который помогает получать наглядные отчеты, записи действий посетителей, отслеживать источники трафика и оценивать эффективность рекламы.</Typography>
        </SectionStyled>
    )
}
