import {Box, Button, IconButton, Paper, Typography, useTheme} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import CalendarIcon from "../../icons/calendarIcon";
import * as React from "react";

interface Props {
    isStatFunnel: boolean
}

export default function Period ({isStatFunnel}: Props) {
    const theme = useTheme();
    const buttonTime = [
        ["Сегодня"],
        ["Вчера"],
        ["За последние 7 дней"],
        ["За последние 30 дней"],
        ["Этот месяц"],
        ["Прошлый месяц"]
    ]
    const [isOpen, setOpen] = React.useState(false)
    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)

    }
    const onAdornmentClick = () => {
        setOpen(old => !old)
        if (isOpenEnd === true) {
            handleCloseEnd()
        }
    }

    const [isOpenEnd, setOpenEnd] = React.useState(false)
    const handleCloseEnd = () => {
        setOpenEnd(false)
    }
    const handleOpenEnd = () => {
        setOpenEnd(true)
    }
    const onAdornmentClickEnd = () => {
        setOpenEnd(old => !old)
        if (isOpen === true) {
            handleClose()
        }
    }
    return (

        <Paper sx={{width: "100%", display: "flex", justifyContent: "space-between",padding: "20px", borderRadius: "12px", flexDirection: isStatFunnel ? "column" : "row", maxWidth: isStatFunnel ? "493px" : undefined, gap: isStatFunnel ?  "30px" : undefined}}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "25px",

                }}
            >
                <Typography variant="h5" sx={{color: "#000000", display: isStatFunnel ? "none" : "inline-flex"}}>Период отображения</Typography>
                <Box sx={{display: "flex", gap: "20px" }}>
                    <Box>
                        <Typography marginBottom="5px">Дата начала</Typography>
                        <DatePicker
                            open={isOpen}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            sx={{
                                width: '169px',

                                '& .MuiOutlinedInput-root': {
                                    borderRadius: "10px",

                                },
                                '& .MuiInputBase-input': {
                                    padding: "12.5px 14px"
                                }
                            }}
                            slotProps={{
                                textField:
                                    { InputProps: { endAdornment: <IconButton onClick={onAdornmentClick}>
                                                <CalendarIcon/>
                                            </IconButton>  } }
                            }}
                        />
                    </Box>
                    <Box>
                        <Typography marginBottom="5px">Дата окончания</Typography>
                        <DatePicker
                            open={isOpenEnd}
                            onClose={handleCloseEnd}
                            onOpen={handleOpenEnd}
                            sx={{
                                width: '169px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: "10px",
                                },
                                '& .MuiInputBase-input': {
                                    padding: "12.5px 14px"
                                }
                            }}
                            slotProps={{
                                textField:
                                    { InputProps: { endAdornment: <IconButton onClick={onAdornmentClickEnd}>
                                                <CalendarIcon/>
                                            </IconButton>  } }
                            }}
                        />
                    </Box>
                </Box>

            </Box>
            <Box sx={{display: "flex", flexWrap: "wrap", alignItems: "end", maxWidth: "502px", width: "100%", columnGap: "20px", rowGap: isStatFunnel ? "20px" : undefined}}>
                {buttonTime.map(button =>
                    <Button
                        sx={{
                            borderBottom: "2px solid #9a9aaf",
                            borderRadius: 0,
                            textTransform: "none",
                            height: "23px",
                            padding: "6px 0",
                            fontSize: " 16px",
                            fontWeight: 400,
                            color: theme.palette.gray.main
                        }}
                    >{button[0]}</Button>
                )}
            </Box>
            <Box sx={{
                display: isStatFunnel ? "none" : "flex",
                flexDirection: "column",
                justifyContent: "end",
                gap: "20px",
            }}>
                <Button variant="pena-contained-dark" sx={{padding: "10px 42px"}}>Применить</Button>
                <Button variant="pena-outlined-purple">Сбросить</Button>
            </Box>

        </Paper>
    )
}
