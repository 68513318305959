import React from 'react';
import Button from '@mui/material/Button';
import SectionStyled from '../../kit/section';
import {Typography} from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";

export default function Hero() {
    const theme = useTheme()
    const isSmallMonitor = useMediaQuery(theme.breakpoints.down(1200));
    const isTablet = useMediaQuery(theme.breakpoints.down(850));

    return (
        <SectionStyled tag={'section'} bg={theme.palette.bg.main} mwidth={'1160px'}
                       sxsect={{
                           minHeight: isTablet ? "750px" : "660px",
                           background: "linear-gradient(#262835, #333647)",
                           paddingBottom: "44px",
                           alignItems: isTablet ? "flex-start" : "center",
                           paddingTop: isTablet ? "50px" : "0",
                           padding: isSmallMonitor ? "150px 40px" : "0",

                       }}
                       sxcont={{
                           display: 'flex',
                           flexDirection: 'column',
                           justifyContent: 'space-between',
                           alignItems: 'baseline',
                           padding: '0 10px',
                           gap: '30px',
                       }}>
            <Typography variant='h2'
                        sx={{
                            maxWidth: "750px",
                            fontSize: isTablet ? "42px" : "70px",
                            lineHeight: "1em",
                        }}
            >Соберем данные там, где не соберет никто</Typography>
            <Typography variant='body1' sx={{maxWidth: '400px', marginBottom: '30px'}}>Текст- это текст, который имеет некоторые характеристики реального письменного</Typography>
            <Button variant="pena-contained-dark">Попробуйте бесплатно</Button>
        </SectionStyled>
    )
}
