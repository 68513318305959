import { Typography, useMediaQuery, Box, useTheme } from "@mui/material";

import logotip from "../icons/logo/logo_Pena.svg";

export default function Footer() {
  const theme = useTheme();

  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        height: isMobile ? "290px" : "236px",
        position: "relative",
        zIndex: 10,
        backgroundColor: "#252734",
        padding: isTablet ? (isMobile ? "40px 18px" : "0") : " 0 0 0 70px",
        justifyContent: "normal",
        alignItems: isMobile ? "normal" : "center",
      }}
    >
      <Box sx={{ ml: isMobile ? "0" : isTablet ? "40px" : "140px" }}>
        <Box
          sx={{
            width: "124px",
            height: "48px",
            backgroundSize: "contain",
          }}
        >
          <img src={logotip} alt="logotip" />
        </Box>
        <Typography sx={{ color: "rgba(114, 112, 116, 1)", fontSize: "14px" }}>(c) 2023 Examplelink.com</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: isMobile ? "40px" : 0,
          ml: isMobile ? "0" : "176px",
        }}
      >
        <Typography sx={{ color: "white", fontSize: "16px", fontWeight: 600, mb: "18px" }}>
          Место под документ 1
        </Typography>
        <Typography sx={{ color: "white", fontSize: "16px", fontWeight: 600 }}>Место под документ 1</Typography>
      </Box>
    </Box>
  );
}
