import { FetchState, TicketMessage } from "@frontend/kitui";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";


interface UnauthTicketStore {
    sessionData: {
        ticketId: string;
        sessionId: string;
    } | null;
    isMessageSending: boolean;
    messages: TicketMessage[];
    apiPage: number;
    messagesPerPage: number;
    lastMessageId: string | undefined;
    isPreventAutoscroll: boolean;
    unauthTicketMessageFetchState: FetchState;
}

export const useUnauthTicketStore = create<UnauthTicketStore>()(
    persist(
        devtools(
            (set, get) => ({
                sessionData: null,
                isMessageSending: false,
                messages: [],
                apiPage: 0,
                messagesPerPage: 10,
                lastMessageId: undefined,
                isPreventAutoscroll: false,
                unauthTicketMessageFetchState: "idle",
            }),
            {
                name: "Unauth tickets"
            }
        ),
        {
            version: 0,
            name: "unauth-ticket",
            storage: createJSONStorage(() => localStorage),
            partialize: state => ({
                sessionData: state.sessionData,
            })
        }
    )
);

export const setUnauthSessionData = (sessionData: UnauthTicketStore["sessionData"]) => useUnauthTicketStore.setState({ sessionData });
export const setIsMessageSending = (isMessageSending: UnauthTicketStore["isMessageSending"]) => useUnauthTicketStore.setState({ isMessageSending });

export const addOrUpdateUnauthMessages = (receivedMessages: TicketMessage[]) => {
    const state = useUnauthTicketStore.getState();
    const messageIdToMessageMap: { [messageId: string]: TicketMessage; } = {};

    [...state.messages, ...receivedMessages].forEach(message => messageIdToMessageMap[message.id] = message);

    const sortedMessages = Object.values(messageIdToMessageMap).sort(sortMessagesByTime);

    useUnauthTicketStore.setState({
        messages: sortedMessages,
        lastMessageId: sortedMessages.at(-1)?.id,
    });
};

export const incrementUnauthMessageApiPage = () => {
    const state = useUnauthTicketStore.getState();

    useUnauthTicketStore.setState({ apiPage: state.apiPage + 1 });
};

export const setUnauthIsPreventAutoscroll = (isPreventAutoscroll: boolean) => useUnauthTicketStore.setState({ isPreventAutoscroll });

export const setUnauthTicketMessageFetchState = (unauthTicketMessageFetchState: FetchState) => useUnauthTicketStore.setState({ unauthTicketMessageFetchState });

function sortMessagesByTime(ticket1: TicketMessage, ticket2: TicketMessage) {
    const date1 = new Date(ticket1.created_at).getTime();
    const date2 = new Date(ticket2.created_at).getTime();
    return date1 - date2;
}
