import {Box, Button, FormControl, MenuItem, Pagination, Paper, Select, Typography, useTheme} from "@mui/material";
import HeaderFull from "../../kit/HeaderFull";
import React, {useState} from "react";
import Section from "../../kit/section";
import number1Icon from "../../icons/number1.svg";
import number2Icon from "../../icons/number2.svg";
import MassageBot from "./massageBot";
import Period from "../create metrica/periodDisplay";
import CustomizedTables from "../create metrica/StatisticTable";
import ArrowDown from "./ArrowDownIcon";
import FunnelImage from "../../images/FunnelImage.png"

const designTypes = [
    ["Ознакомление с брендом"],
    ["Интерес"],
    ["Желание"],
    ["Действие"]
] as const;
type DesignType = typeof designTypes[number];


export default function StatisticFunnel() {
    const theme = useTheme();
    const [designType, setDesignType] = useState<DesignType | "">(designTypes[0]);


    return (
        <>
            <HeaderFull/>
            <Section bg={theme.palette.gray.light} tag={'section'} mwidth={"1160px"} sxsect={{height: "100%"}}
                     sxcont={{
                         display: "flex",
                         flexDirection: "column",
                         alignItems: "flex-start",
                         height: "100%",
                         padding: "25px 0",
                         gap: "20px"
                     }}
            >
                <Typography sx={{color: theme.palette.gray.main, fontSize: "12px"}}>Настройка воронки</Typography>
                <Typography
                    variant="h2"
                    sx={{
                        color: "#000000",
                        fontSize: "36px",
                        lineHeight: 'normal'
                    }}
                >
                    Этапы воронки
                </Typography>
                <Paper
                    sx={{width: "100%", borderRadius: "12px", background: "#ffffff",
                        padding: "20px",
                        marginTop: "10px",
                        boxShadow: "0px 2.76726px 8.55082px 0px rgba(210, 208, 225, 0.07), 0px 6.6501px 20.54881px 0px rgba(210, 208, 225, 0.10), 0px 12.52155px 38.6916px 0px rgba(210, 208, 225, 0.12), 0px 22.33631px 69.0192px 0px rgba(210, 208, 225, 0.14), 0px 41.77761px 129.09282px 0px rgba(210, 208, 225, 0.17), 0px 100px 309px 0px rgba(210, 208, 225, 0.24)",
                    }}>
                    <img src={number1Icon}/>
                    <Box sx={{display: "flex", marginTop: "10px", gap: "20px"}}>
                        <Box sx={{width: "50%"}}>
                            <Typography variant='h5'>Сообщения бота</Typography>
                            <Box sx={{
                                borderRadius: "10px",
                                border: "1px solid #9A9AAF",
                                background: "#ECECF3",
                                padding: "50px 20px 20px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "20px",
                                marginTop: "19px"
                            }}
                            >
                                <MassageBot isSelf={false} text={'Текст-заполнитель — это текст, который '} messageBackgroundColor={'#9A9AAF'}/>
                                <MassageBot isSelf={true} text={'/products'} messageBackgroundColor={'#FFFFFF'}/>
                                <MassageBot isSelf={false} text={'Текст-заполнитель — это текст'} messageBackgroundColor={'#9A9AAF'}/>

                            </Box>
                        </Box>
                        <Box sx={{width: "50%"}}>
                            <Typography variant='h5'>Создание этапов</Typography>
                            <Box
                                sx={{
                                    borderRadius: "10px",
                                    border: "1px solid #9A9AAF",
                                    background: "#FFFFFF",
                                    marginTop: "19px",
                                    padding: "20px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "20px",

                                }}
                            >
                                <Box>
                                    <Typography sx={{color: theme.palette.purple.main, fontWeight: 500}}>от</Typography>
                                    <Typography variant="body1" fontWeight="500" marginTop="5px">1-й этап воронки</Typography>
                                    <Box
                                        sx={{
                                            border: `1px dashed ${theme.palette.purple.main}`,
                                            marginTop: "10px",
                                            height: "59px",
                                            display: "flex",
                                            justifyContent: "start",
                                            alignItems: "center",
                                            borderRadius: "8px",
                                            paddingLeft: "23px"
                                        }}>
                                        <Typography sx={{color: theme.palette.gray.main, fontSize: "14px"}}>Перетащите сообщение из диалога слева сюда</Typography>
                                    </Box>
                                </Box>
                                <Box >
                                    <Typography sx={{color: theme.palette.purple.main, fontWeight: 500}} >до</Typography>
                                    <Typography variant="body1" fontWeight="500" marginTop="5px">2-й этап воронки</Typography>
                                    <Box sx={{
                                        border: `1px dashed ${theme.palette.purple.main}`,
                                        marginTop: "10px",
                                        height: "59px",
                                        display: "flex",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        borderRadius: "8px",
                                        paddingLeft: "23px"
                                    }}><Typography sx={{color: theme.palette.gray.main, fontSize: "14px"}}>Перетащите сообщение из диалога слева сюда</Typography></Box>
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display: "flex",
                        justifyContent: "end", marginTop: "20px"}}>
                        <Button variant="pena-contained-dark" sx={{backgroundColor: theme.palette.gray.main, borderColor: theme.palette.gray.main}} >Применить</Button>
                    </Box>

                </Paper>
                <Typography
                    variant="h2"
                    sx={{
                        color: "#000000",
                        fontSize: "36px",
                        lineHeight: 'normal',
                        marginTop: "28px"
                    }}
                >
                    Статистика
                </Typography>
                <Typography>этапов воронки</Typography>
                <Paper sx={{width: "100%",
                    gap: "20px",
                    padding: "20px",
                    display: "flex",
                    borderRadius: "12px",
                    border: "1px solid #FFF",
                    background: "#F2F3F7",
                    boxShadow: "0px 2.76726px 8.55082px 0px rgba(210, 208, 225, 0.07), 0px 6.6501px 20.54881px 0px rgba(210, 208, 225, 0.10), 0px 12.52155px 38.6916px 0px rgba(210, 208, 225, 0.12), 0px 22.33631px 69.0192px 0px rgba(210, 208, 225, 0.14), 0px 41.77761px 129.09282px 0px rgba(210, 208, 225, 0.17), 0px 100px 309px 0px rgba(210, 208, 225, 0.24)",
                }}>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px"}}>
                        <img width="36" src={number2Icon}/>
                        <Typography variant="h5" sx={{color: "#000000"}}>Период отображения</Typography>
                        <Period isStatFunnel={true}/>
                    </Box>
                    <Box sx={{marginTop: "59px", display: "flex", flexDirection: "column", gap: "20px",}}>
                        <Typography variant="h5" sx={{color: "#000000"}}>Этап воронки</Typography>
                        <Paper sx={{padding: "20px", borderRadius: "12px",}}>
                            <FormControl
                                fullWidth
                                size="small"
                                sx={{
                                    width: "100%",
                                    minWidth: "200px",
                                    height: "48px",
                                }}
                            >
                                <Select
                                    id="category-select"
                                    variant="outlined"
                                    value={ designType }
                                    displayEmpty
                                    onChange={e => setDesignType(e.target.value as DesignType)}
                                    sx={{
                                        height: "48px",
                                        borderRadius: "8px",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: `1px solid ${theme.palette.purple.main} !important`,
                                        },
                                        "& .MuiInputBase-root.MuiOutlinedInput-root:first-child": {
                                            display: "inline-flex"
                                        },
                                        "& .MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-icon": {
                                            display: "inline-flex !important"
                                        }
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                mt: "8px",
                                                p: "4px",
                                                borderRadius: "8px",
                                                border: "1px solid #EEE4FC",
                                                boxShadow: "0px 8px 24px rgba(210, 208, 225, 0.4)",
                                            },
                                        },
                                        MenuListProps: {
                                            sx: {
                                                py: 0,
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "8px",
                                                "& .Mui-selected": {
                                                    backgroundColor: theme.palette.background.default,
                                                    color: theme.palette.purple.main,
                                                },
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        sx: {
                                            color: theme.palette.purple.main,
                                            display: "flex",
                                            alignItems: "center",
                                            px: "9px",
                                            gap: "20px",
                                        },
                                    }}
                                    IconComponent={(props) => <ArrowDown {...props} />}
                                >
                                    {designTypes.map((type) => (
                                        <MenuItem
                                            key={type[0]}
                                            value={type[0]}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "20px",
                                                p: "4px",
                                                borderRadius: "5px",
                                                color: theme.palette.gray.main,
                                            }}
                                        >
                                            {/*{type[1](type[0] === designType ? theme.palette.orange.main : theme.palette.gray.main)}*/}
                                            {type[0]}


                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography sx={{color: theme.palette.gray.main, fontSize: "16px", marginTop: "79px"}}   >*текст-заполнитель —
                                это текст, который имеет текст</Typography>
                        </Paper>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                        gap: "20px",
                    }}>
                        <Button variant="pena-contained-dark" sx={{padding: "10px 42px"}}>Применить</Button>
                        <Button variant="pena-outlined-purple">Сбросить</Button>
                    </Box>
                </Paper>


                <Box sx={{display: "flex", gap: "20px"}}>
                    <Box sx={{display: "flex", flexDirection: "column", alignItems: "end", gap: "20px"}}>
                        <CustomizedTables/>
                        <Box sx={{marginTop: "20px"}}>
                            <Pagination
                                variant="pena-pagination"
                                count={5}
                                shape="rounded"
                            />
                        </Box>

                    </Box>

                    <Paper sx={{padding: "50px 20px 20px",
                        height: "90%",
                        borderRadius: "12px",
                        border: "1px solid #FFF",
                        background: "rgba(255, 255, 255, 0.50)",
                        boxShadow: "0px 2.76726px 8.55082px 0px rgba(210, 208, 225, 0.07), 0px 6.6501px 20.54881px 0px rgba(210, 208, 225, 0.10), 0px 12.52155px 38.6916px 0px rgba(210, 208, 225, 0.12), 0px 22.33631px 69.0192px 0px rgba(210, 208, 225, 0.14), 0px 41.77761px 129.09282px 0px rgba(210, 208, 225, 0.17), 0px 100px 309px 0px rgba(210, 208, 225, 0.24)"
                    }}>
                        <img src={FunnelImage}/>
                        <Typography sx={{color: theme.palette.gray.main, fontSize: "16px", marginTop: "30px"}}>*воронка продаж</Typography>
                    </Paper>
                </Box>
            </Section>
        </>
    )
}